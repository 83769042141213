<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12">
        <v-toolbar>
          <v-toolbar-title
            ><v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
              <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
            ></v-toolbar-title
          >
          <v-autocomplete
            v-model="select"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Qual consulta está procurando?"
            solo-inverted
          ></v-autocomplete>
        </v-toolbar>
      </div>
    </div>

    <div class="row mt-6">
      <div class="col-md-12">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">Nome</th>
                <th class="">Médico</th>
                <th class="">Status</th>
                <th class="">Data</th>
                <th class="">Especialidade</th>
                <th class="">Opções</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Antônio Galvão</td>
                <td>119.456.789-87</td>
                <td>123485678979</td>
                <td>1111111</td>
                <th class="">Nenhuma</th>
                <td>
                  <router-link to="" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-search</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>

                  <router-link to="" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-edit</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>
                </td>
              </tr>

              <tr>
                <td>Antônio Galvão</td>
                <td>119.456.789-87</td>
                <td>123485678979</td>
                <td>1111111</td>
                <th class="">Nenhuma</th>
                <td>
                  <router-link to="" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-search</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>

                  <router-link to="" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-edit</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>Antônio Galvão</td>
                <td>119.456.789-87</td>
                <td>123485678979</td>
                <td>1111111</td>
                <th class="">Nenhuma</th>
                <td>
                  <router-link to="" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-search</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>

                  <router-link to="" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-edit</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>Antônio Galvão</td>
                <td>119.456.789-87</td>
                <td>123485678979</td>
                <td>1111111</td>
                <th class="">Nenhuma</th>
                <td>
                  <router-link to="" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-search</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>

                  <router-link to="" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-edit</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>Antônio Galvão</td>
                <td>119.456.789-87</td>
                <td>123485678979</td>
                <td>1111111</td>
                <th class="">Nenhuma</th>
                <td>
                  <router-link to="/NovaTriagem" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-search</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>

                  <router-link to="/NovaTriagem" v-slot="{ href, navigate }">
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn color="#1dd1a1" class="mr-2 mb-2 mt-2">
                        <v-icon center small color="#FFF"
                          >fas fa-edit</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "PainelConsultas",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lista de consultas médicas" }
    ]);
  }
};
</script>

<style>
.textStyle {
  color: white;
  font-size: 1.2em;
}
</style>
